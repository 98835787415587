<template>
  <base-view title="Reglas CFDI" icon="check-square">
    <div>
      <check-authorization :requiresAuthorizations="['indice reglas']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Reglas aplicadas a la estructura del Comprobante Fiscal Digital
          </b-card-header>

          <b-card-body class="p-2">
            <se-reglas-estructura-cfdi-list @on-got-item="showForm = true"></se-reglas-estructura-cfdi-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import SeReglasEstructuraCfdiList from '@/components/SE/ReglasEstructuraCfdi/SeReglasEstructuraCfdiList'
import { mapActions, mapGetters } from 'vuex'

const STORE_MODULE = 'reglasEstructuraCfdiModule'

export default {
  name: 'SeReglasEstructuraCfdiMainView',

  components: {
    SeReglasEstructuraCfdiList
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      tableData: [{
        name: 'Ziuta',
        surname: 'Kozak',
        children: [
          { name: 'Czerwony Kapturek', surname: 'Kozak' }
        ]
      }, {
        name: 'Koziolek',
        surname: 'Matolek',
        children: [
          {
            name: 'Timon',
            surname: 'Matolek',
            children: [
              {
                name: 'Timon Junior', surname: 'Matolek'
              }
            ]
          }
        ]
      }, {
        name: 'Pumba',
        surname: 'unknown'
      }],
      columns: [
        {
          label: 'Name',
          id: 'name'
        },
        {
          label: 'Surname',
          id: 'surname'
        }
      ]
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, ['isEditingResource'])
  },

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>
.table{
  width: 90%;
  margin: auto;
}
</style>
