<template>
  <div class="row">
    <div
      class="indentation"
      :style="{ width: leftPadding + 'px' }"
    />
    <div
      v-for="key in defaultOrder"
      :key="key"
      class="cell"
    >
      <div v-if="key!=='reglas'">
        {{ rowData[key] }}
      </div>
      <div v-else-if="rowData[key]">
        Reglas:
        <ul>
          <li v-for="regla in rowData[key]" :key="regla">
            {{ regla.regla.nombre }}: {{ regla.regla.descripcion }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreeDefaultLeaf',
  props: {
    rowData: {
      type: Object,
      default: () => { return {} }
    },
    defaultOrder: {
      type: Array,
      default: () => { return [] }
    },
    depth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    leftPadding: function () {
      return this.depth * 17
    }
  }
}
</script>

<style scoped>
</style>
